import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AppContext from "../context/AppContext";
import { useCookies } from 'react-cookie';


export default function Home(){
  const { id } = useParams();

  const [usuario, setUsuario] = useCookies(['usuario']);
  const { api } = useContext(AppContext);
  const [cookies, setCookie] = useCookies(['cliente']);
  let nombre = cookies?.cliente?.nombre || "";






  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchUsuario = async () => {
      try {
        const response = await fetch(`${api}/api/usuarios/name/${id}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ name: id }) // Datos a enviar en el cuerpo de la solicitud
        });
        const data = await response.json();
        setUsuario('usuario', data, { path: '/', maxAge: 604800 });


      } catch (error) {
        console.log('Error al obtener el usuario:', error);
      }
    };

    fetchUsuario();
  }, [id]);


  const getDynamicStyles = () => {
    // Lógica para determinar los estilos según el identificador
    if (usuario.usuario && usuario.usuario.img) {

        return {
          background: `url(${usuario.usuario.img}) center`,
          color: 'white',
        };

    }
}

  const dynamicStyles = getDynamicStyles();

    return(
          usuario.usuario  ? 

        <section id="hero" className="d-flex align-items-center" style={dynamicStyles}>
        <div className="container position-relative text-center text-lg-start" data-aos="zoom-in" data-aos-delay="100">
          <div className="row">
            <div className="col-lg-8">

              <h1>Bienvenido {nombre ? nombre.toUpperCase() : ''} a <span >{usuario.usuario.local} </span></h1>   <br/>
              <h2> ELEGÍ, PEDÍ y PAGÁ desde tu celular.</h2>
              <h2>¡Mejor servicio, más tiempo para disfrutar!</h2>
              {/* <h2>Todo con solo un par de clicks</h2> */}

              <div className="btns">
                <Link to={`/${usuario.usuario.local}/menu`} className="btn-menu animated fadeInUp scrollto">Nuestro Menú</Link>
                 {(usuario.usuario.take || usuario.usuario.delivery )&& ( <Link to={`/${usuario.usuario.local}/ubicacion`} className="btn-book animated fadeInUp scrollto">{usuario.usuario.take && ('Take Away -' )} {usuario.usuario.delivery && ( 'Delivery') }</Link>) }
              </div>
            </div>
            <div className="col-lg-4 d-flex align-items-center justify-content-center position-relative" data-aos="zoom-in" data-aos-delay="200">
              <Link  to={`/${usuario.usuario.local}/menu`}   className="glightbox play-btn"></Link>
            </div>

          </div>
        </div>
      </section>

    : <div id="preloader"></div> 

    )

}