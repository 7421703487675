import React, { useContext, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import AppContext from '../context/AppContext';
import useInitialState from '../hooks/useInitialState';
import ItemCount from '../components/itemCount';

export default function Productos(props) {

    const [comentario, setComentario] = useState('');
    const [qty, setQty] = useState(0);
    const [img, setImg] = useState();
    const currentOverflow = document.body.style.overflow;
    const currentPaddingRight = document.body.style.paddingRight;
    
    const { addToCart, cart, restToCart } = useContext(AppContext);
    useEffect(() => {
        const handleBackButton = (event) => {
          event.preventDefault(); // Evita que la página vuelva a la anterior
          const modal = document.getElementById(`exampleModal-${img}`);
          const modalClass = document.querySelector("body");
          const backdrop = document.querySelector('.modal-backdrop');
   
          if(modal){
            
         modal.classList.remove('show');
         modal.classList.remove('hide');
          modal.style.display = 'none';
          document.body.classList.remove('modal-open');

          document.body.style.removeProperty('overflow');
          document.body.style.removeProperty('padding-right');
    
          }
      
         // modal.classList.remove('modal');
          //modal.classList.remove('fade');
          if (backdrop) {
            backdrop.remove(); // eliminar el backdrop
          }
          
        };
    
        window.history.pushState(null, '', window.location.href); // Agrega una nueva entrada de historial para evitar volver atrás
        window.addEventListener('popstate', handleBackButton);
    
        return () => {
          window.removeEventListener('popstate', handleBackButton);
        };
      }, [img]);

    return (
        <div className="col-lg-6 menu-item" data-categoria={props.categoria}>
            <img src={props.img} loading="lazy" className="menu-img" data-bs-toggle="modal" id="menumodal" onClick={ ()=> setImg(props.id)} data-bs-target={`#exampleModal-${props.id}`} />
            <a className="categoriaMenu">{props.categoria}</a>

            <div className="menu-content">
                <a>{props.nombre}</a> <span>${props.price}</span>
            </div>

            <div className="menu-ingredients">
                {props.ingredients} <ItemCount img={props.img} categoria={props.categoria} nombre={props.nombre} price={props.price} stock={props.stock} initial={1} id={props.id} />
            </div>

            <div className="modal fade" id={`exampleModal-${props.id}`} tabIndex="-1" aria-labelledby={`exampleModalLabel-${props.id}`} aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content modal-contentMenu">
                        <div className="modal-header">
                           <span data-bs-dismiss="modal" className="btn-close material-icons-outlined" translate="no">close</span>
                        </div>

                        <div className="modal-body modal-bodyMenu">
                            <div className="accordion accordion-flush" id={`accordionFlushExample-${props.id}`}>

                                <div className="gslide-media gslide-image">
                                    <img src={props.img} loading="lazy" className="zoomable imgModal" data-bs-toggle="modal" data-bs-target={`#exampleModal-${props.id}`} />
                                </div>
                                <div className="flex">
                                    <h4 className='colorprimaryMenu'>{props.nombre}</h4> <h5>${props.price}</h5>
                                </div>
                                <p className="menu">{props.ingredients}</p>
                                <div className="form-group mt-3">
                                        <textarea className="form-control" name="message" rows="2" placeholder="Comentario" onChange={(e) => setComentario(e.target.value)}></textarea>
                                    </div>

                                <div className="center colorprimary">
                                    {/* {
                                        cart.card.map((props) => (

                                            <>


                                                <div >
                                                    <span onClick={() => {
                                                        if (props.qty == 1) return;
                                                        // setShowError(true);
                                                        restToCart(props, -1);
                                                    }}
                                                        // disabled={qty - props.qty <= 0 ? true :  false} 
                                                        className="material-symbols-outlined">
                                                        remove_circle_outline
                                                    </span>

                                                    <span id="cantidad" className='colorprimary'>{props.qty} </span>





                                                </div>

                                              

                                            </>))} */}
                                    
                                    <ItemCount className='colorprimary' img={props.img} categoria={props.categoria} nombre={props.nombre} price={props.price} stock={props.stock} initial={1} id={props.id} comentario={comentario}/>


                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}