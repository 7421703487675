import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import AppContext from "../context/AppContext";


export default function Contact() {

  const [usuario] = useCookies(['usuario']);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [ok, setOk] = useState(0);
  const { api } = useContext(AppContext);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true)
    
    const userEmail = usuario.usuario.email
    const subject = encodeURIComponent(event.target.subject.value);
    const message = encodeURIComponent(event.target.message.value);

    // Construir el enlace para abrir Gmail con el mensaje predefinido
    const gmailLink = `https://mail.google.com/mail/?view=cm&to=${userEmail}&su=${subject}&body=${message}`;

    const outlookLink = `https://outlook.office365.com/mail/deeplink/compose?to=${userEmail}&subject=${subject}&body=${message}`;

    // Abrir Gmail en una nueva pestaña
    window.open(outlookLink, '_blank');

    // Actualizar el estado para mostrar el mensaje enviado
   // setOk(200);
    setIsLoading(false);
  };

  return (

    usuario.usuario ?

      <section id="contact" className="sectionMenu contact">
        <div className="container" data-aos="fade-up">

          <div className="section-title">
            <h2>Contacto</h2>
            <p>Contactanos</p>
          </div>
        </div>

        <div data-aos="fade-up">
          <iframe className="mapMenu" src={usuario.usuario.map} frameborder="0" allowfullscreen></iframe>
        </div>

        <div className="container" data-aos="fade-up">

          <div className="row mt-5">

            <div className="col-lg-4">
              <div className="info">

                {usuario.usuario.direccion &&
                  <div className="address">
                    <i className="bi bi-geo-alt"></i>
                    <h4>Dirección:</h4>
                    <p>{usuario.usuario.direccion}</p>
                  </div>}

                {usuario.usuario.horarios &&
                  <div className="open-hours">
                    <i className="bi bi-clock"></i>
                    <h4>Horario:</h4>
                    <p>
                      {usuario.usuario.horarios}
                    </p>
                  </div>
                }
                {/* <div className="email">
                <i className="bi bi-envelope"></i>
                <h4>Email:</h4>
                <p> {usuario.usuario.email} </p>
              </div> */}

                <div className="phone">
                  <i className="bi bi-phone"></i>
                  <h4>Teléfono:</h4>
                  <p >{usuario.usuario.telefono}</p>
                </div>

              </div>

            </div>

            <div className="col-lg-8 mt-5 mt-lg-0">

              <form onSubmit={handleSubmit} role="form" className="php-email-form">
                <div className="row">
                  <div className="col-md-6 form-group">
                    <input type="text" name="name" className="form-control" id="name" placeholder="Tu nombre" required />
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <input type="number" className="form-control" name="telefono" id="telefono" placeholder="Tu teléfono" required />
                  </div>
                </div>
                <div className="form-group mt-3">
                  <input type="text" className="form-control" name="subject" id="subject" placeholder="Motivo" required />
                </div>
                <div className="form-group mt-3">
                  <textarea className="form-control" name="message" rows="8" placeholder="Mensaje" required></textarea>
                </div>

                <div className="my-3">
                  <div className="loading">Cargando...</div>
                  <div className="error-message"></div>
                  <div className="sent-message">Tu mesanje fue enviado a tu cansilla de correo electrónico, para hacer el envio más facil. ¡Gracias!</div>
                </div>
                {ok === 200 ? <div className='center' data-bs-dismiss="modal"><i className="bi bi-check-circle-fill"></i>  <div className="sent-message d-block">Tu mesanje fue enviado. ¡Gracias!</div></div> : isLoading ?
                  <div className="text-center" ><div className="buttonMenu loading d-block"></div></div> : <div className="text-center d-block"><button className="buttonMenu" type="submit">Enviar Mensaje</button></div>}
              </form>

            </div>

          </div>

        </div>
      </section>
      : <></>
  )
}