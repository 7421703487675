import React, { useContext, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { initMercadoPago, Payment, Wallet } from '@mercadopago/sdk-react';
import AppContext from "../context/AppContext";
import { useState } from "react";
import { useCookies } from "react-cookie";


export default function Cart() {

  const [usuario] = useCookies(['usuario']);
  const { pedido, api, setClick, total } = useContext(AppContext);
  //const [total, setTotal] = useState(0);
  const [mp, setMp] = useState(0);
  const [porcentaje, setPorcentaje] = useState(0);
  let location = useLocation();
  const [preference, setPreference] = useState(false);
  const serweb = (porcentaje === 0)  ? 'Bonificado' : '$' + (total / (porcentaje * 100)).toFixed(1);
  const sumTotal = (porcentaje === 0)  ? total : (Number(serweb.replace('$', '')) + total).toFixed(1);
  const currentUrl = window.location.href;
  const rejected = currentUrl.includes('rejected');
  let uno = true;
  const mesa = 0; //se debe agregar al estado global
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  useEffect(() => {

    const fetchData = async () => {
      try {
        
        const data = { 
          producto: location.state.data.producto.map(producto => {
            return {
              itemId: producto.id,
              itemTitle: producto.name,
              itemDescription: producto.name,
              itemQuantity: producto.cantidad,
              currencyId: 'ARS',
              itemUnitPrice: producto.precio

            };
          }),
          cliente: usuario.usuario.id,
          pedido: location.state.pedido,
          //cliente: { nombre: user.nombre, email: user.email, telefono: user.telefono },
        };


        const response = await fetch(`${api}/api/pay`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },

          body: JSON.stringify(data),
        });

        const responseData = await response.json();

        setPreference(responseData.preferenceId);


      } catch (error) {
        console.log('Error al obtener el usuario:', error);
      }

    };

    uno ?
      fetchData() : console.log('uno');
    uno = false;

  }, []);

  useEffect(() => {
    const fetchDt = async () => {
      try {
        const requestOptions = {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        };

        const response = await fetch(`${api}/api/dt/${usuario.usuario.id}`, requestOptions);
        const result = await response.json();
        setMp(result.mp)
        setPorcentaje(result.porcentaje);
      } catch (error) {
        console.log('Error al obtener el usuario:', error);
      }

    };


    fetchDt();


  }, []);

  initMercadoPago(mp, { locale: 'es-AR' });




  const customization = {
    /* paymentMethods: {
       creditCard: "all",
       debitCard: "all",
       mercadoPago: "all",
     },*/
    visual: {
      buttonBackground: 'white',
    },
  };



  // const onSubmit = async (
  //   { selectedPaymentMethod, formData }
  // ) => {
  //   // callback llamado al hacer clic en el botón enviar datos
  //   return new Promise((resolve, reject) => {
  //     fetch(`${api}/api/payed`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(formData),
  //     })
  //       .then((response) => response.json())
  //       .then((response) => {
  //         // recibir el resultado del pago
  //         resolve();
  //         setUnico(1);
  //       })
  //       .catch((error) => {
  //         // manejar la respuesta de error al intentar crear el pago
  //         reject();
  //       });
  //   });

  // };
  const onError = async (error) => {

    console.log(error);
  };
  const onReady = async () => {

  };



  return (
    <section id="contact" className="sectionMenu section-bg">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>Pedido y Pagos</h2>
          <p>Pago</p>
        </div>
      </div>


      <div className="container" data-aos="fade-up">

        <div className="alert alert-warning section-bg" role="alert">
          <h6>¡Estás a un paso de abonarlo!</h6>
        </div>
        {/* <div id="cart" className="section-p1">
          <table width="100%">
            <thead>
              <tr>
                <td>Imagén</td>
                <td>Producto</td>
                <td>Precio</td>
                <td>Cantidad</td>
                <td id="oculta">Total</td>
              </tr>
            </thead>
            <tdbody>
              <tr>
                <td><img src="img/products/f1.jpg" alt="" /></td>
                <td>NombreProducto</td>
                <td>$ 3333.55</td>
                <td><input type="number" name="" id="" value="1" /></td>
                <td id="oculta">$ 3333.55</td>
              </tr>
              <tr>
                <td><img src="img/products/f2.jpg" alt="" /></td>
                <td>NombreProducto</td>
                <td>$ 3333.5</td>
                <td><input type="number" name="" id="" value="1" /></td>
                <td id="oculta">$ 3333.55</td>
              </tr>
              <tr>
            
                <td><img src="img/products/f3.jpg" alt="" /></td>
                <td>NombreProducto</td>
                <td>$ 3333.55</td>
                <td><input type="number" name="" id="" value="1" /></td>
                <td id="oculta">$ 3.333.55</td>
              </tr>
            </tdbody>
          </table>
        </div> */}
        <br></br>

        <div id="cart-add" className="section-p1 center">


          <div id="subtotal" className="align-right">
            {rejected ? <div id="subtotal" className="align-right">
              <h4 className="center"><i class="bi bi-x-circle"></i>Pago Rechazado</h4>
            </div> : <></>}

            <h4 className="center">Tu Resumen</h4>
            <table className="totales">
              <tr>
                <td>Subtotal</td>
                <td className="precios">${  typeof total === 'number' ? total.toFixed(2) : ''}</td>
              </tr>
              <tr>
                <td><h6>Servicio web </h6></td>

                {/* <OverlayTrigger placement="top" overlay={tooltip}>
      <i className="bi bi-info-circle"></i>
      </OverlayTrigger> */}
                <td className="precios"><h6>{serweb}</h6> </td>
              </tr>
              <tr>
                <td><strong>Total</strong></td>
                <td className="precios"><strong>${  typeof sumTotal === 'number' ? sumTotal.toFixed(2) : ''}</strong></td>
              </tr>
              <tr>

             
              </tr>
            </table>

            <div className="grid martop4">
            <span className=""> <input type="text" placeholder="Ingresa tu Cupón" className='cupon' />
              <button className="buttonMenu ">Agregar</button></span>
            </div>
            <button className="normal second topbot" data-bs-toggle="modal" data-bs-target='#exampleModal'>Formas de Pagos</button>

            {preference ?

              <Wallet initialization={{ preferenceId: preference, redirectMode: 'modal', auto_return: "approved" }}
                customization={customization} />
              : <button className="normal second center"><div className="loading d-block"></div></button>
            }

           

            {/*
           
           // <Payment
            //   initialization={initialization}
            //   customization={customization}
            //   onSubmit={onSubmit}
            //   onReady={onReady}
            //   onError={onError}
          
            // />   
    : <> </>*/}





          </div>

          <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content modal-contentMenu">
                <div className="modal-header">
                  <span data-bs-dismiss="modal" className="btn-close material-icons-outlined" translate="no">close</span>

                </div>
                <div className="modal-body modal-bodyMenu">

                  <form >


                    <div class="accordion" id="accordionExample">
                      <div class="accordion-item">
                        <h2 class="accordion-header">
                          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Pago Efectivo
                          </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                          <div class="accordion-body">
                            Al retirar el pedido podrás pagar con efectivo.
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Pago Tarjeta Debito-Crédito
                          </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                          <div class="accordion-body">
                            Al retirar el pedido podrás pagar con tu tarjeta de debito o crédito.
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            Transferencias - Envio Dinero MP
                          </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                          <div class="accordion-body">
                            Sólo se acepta transferencia directa desde el mismo banco emisor al banco receptor.
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>

                </div>

              </div></div>

          </div>

          {/* <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-contentMenu">
              <div className="modal-header">
              <span data-bs-dismiss="modal" className="btn-close material-icons-outlined" translate="no">close</span>

            </div>
          
               
              </div></div>

          </div> */}

         
        </div>
      </div>
    </section>
  )
}