import { Link } from "react-router-dom";


export default function ErrorMenu() {







    return (

        <section className=" ">
            <div class="moon"></div>
            <div class="moon__crater moon__crater1"></div>
            <div class="moon__crater moon__crater2"></div>
            <div class="moon__crater moon__crater3"></div>

            <div class="star star1"></div>
            <div class="star star2"></div>
            <div class="star star3"></div>
            <div class="star star4"></div>
            <div class="star star5"></div>

            <div class="errorMenu">  <Link to={'/ClickMenu'} class="error__button error__button--active">INICIO</Link>
                <button class="error__button">CONTACTO</button>
                <div class="error__title">Uy...</div>
              
                <div class="error__subtitle">...algo salio mal</div>
                <div class="error__description">Error: Contactanos y lo resolveremos</div>
                
            </div>

            <div class="astronaut">
                <div class="astronaut__backpack"></div>
                <div class="astronaut__body"></div>
                <div class="astronaut__body__chest"></div>
                <div class="astronaut__arm-left1"></div>
                <div class="astronaut__arm-left2"></div>
                <div class="astronaut__arm-right1"></div>
                <div class="astronaut__arm-right2"></div>
                <div class="astronaut__arm-thumb-left"></div>
                <div class="astronaut__arm-thumb-right"></div>
                <div class="astronaut__leg-left"></div>
                <div class="astronaut__leg-right"></div>
                <div class="astronaut__foot-left"></div>
                <div class="astronaut__foot-right"></div>
                <div class="astronaut__wrist-left"></div>
                <div class="astronaut__wrist-right"></div>


                <div class="astronaut__head">
                    <canvas id="visor" width="60px" height="60px"></canvas>
                    <div class="astronaut__head-visor-flare1"></div>
                    <div class="astronaut__head-visor-flare2"></div>
                </div>
                <div class="astronaut__cord">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
                        <path class="hose" d="M20,100 C80,50 120,150 180,100" fill="none" stroke-width="10" />
                        <circle class="connector" cx="12" cy="100" r="12" fill="#2f3640" />
                        <circle class="connector" cx="180" cy="100" r="12" fill="#e67e22" />
                    </svg>
                </div>

            </div></section>
    )
}