import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import AppContext from "../context/AppContext";


export default function Subcripcion() {

  const [usuario] = useCookies(['usuario']);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [ok, setOk] = useState(0);
  const { api } = useContext(AppContext);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true)
    
    const userEmail = usuario.usuario.email
    const subject = encodeURIComponent(event.target.subject.value);
    const message = encodeURIComponent(event.target.message.value);

    // Construir el enlace para abrir Gmail con el mensaje predefinido
    const gmailLink = `https://mail.google.com/mail/?view=cm&to=${userEmail}&su=${subject}&body=${message}`;

    const outlookLink = `https://outlook.office365.com/mail/deeplink/compose?to=${userEmail}&subject=${subject}&body=${message}`;

    // Abrir Gmail en una nueva pestaña
    window.open(outlookLink, '_blank');

    // Actualizar el estado para mostrar el mensaje enviado
   // setOk(200);
    setIsLoading(false);
  };

  return (

    usuario.usuario ?

      <section id="contact" className="sectionMenu contact">
    

{/*         <div className="container" data-aos="fade-up">

          <div className="section-title">
           >
          </div>
        </div>    <div data-aos="fade-up">
          <iframe className="mapMenu" src={usuario.usuario.map} frameborder="0" allowfullscreen></iframe>
        </div>*/}
        <div class="container"> 
        <section id="pricing" class="pricing section">

            <div class="container section-title aos-init aos-animate" data-aos="fade-up">
            <h2>tu Menú</h2>
            <p>Aliados</p>
            </div>

            <div class="container aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">

              <div class="row g-4">

                <div class="col-lg-4">
                  <div class="pricing-item">
                    <h3>Pack MENÚ</h3>
                    <div class="icon">
                      <i class="bi bi-box"></i>
                    </div>
                    <h4><sup>$</sup>11.000<span> / mes</span></h4>
                    <ul>
                      <li><i class="bi bi-check"></i> <span>Primer Mes Invitación</span></li>
                      <li><i class="bi bi-check"></i> <span>Menú Exclusivo + TAKE AWAY</span></li>
                      <li><i class="bi bi-check"></i> <span>APP Administrador ALIADOS</span></li>
                      <li class="na"><i class="bi bi-x"></i> <span>E-commerce + Delivery</span></li>
                      <li class="na"><i class="bi bi-x"></i> <span>Personalización + Estadisticas</span></li>
                    </ul>
                    <div class="text-center"><button class="buy-btn">SUMARME</button></div>
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="pricing-item featured">
                    <h2>Pack PRO</h2>
                    <div class="icon">
                      <i class="bi bi-rocket"></i>
                    </div>

                    <h4><sup>$</sup>16.000<span> / mes</span></h4>
                    <ul>
                      <li><i class="bi bi-check"></i> <span>Primer Mes Invitación</span></li>
                      <li><i class="bi bi-check"></i> <span>Menú Exclusivo + TAKE AWAY</span></li>
                      <li><i class="bi bi-check"></i> <span>APP Administrador ALIADOS</span></li>
                      <li><i class="bi bi-check"></i> <span>E-commerce + Delivery</span></li>
                      <li class="na"><i class="bi bi-check"></i> <span>Personalización + Estadisticas</span></li>
                    </ul>
                    <div class="text-center"><button class="buy-btn">SUMARME</button></div>
                  </div>
                </div>

                <div class="col-lg-4">
                  <div class="pricing-item">
                    <h3>Pack PLUS</h3>
                    <div class="icon">
                      <i class="bi bi-send"></i>
                    </div>
                    <h4>Personalizado</h4> 
                    {/* <h4><sup>$</sup>49<span> / month</span></h4> */}
                    <ul>
                      <li><i class="bi bi-check"></i> <span>Primer Mes Invitación</span></li>
                      <li><i class="bi bi-check"></i> <span>Menú Exclusivo + TAKE AWAY</span></li>
                      <li><i class="bi bi-check"></i> <span>APP Administrador ALIADOS</span></li>
                      <li><i class="bi bi-check"></i> <span>E-commerce + Delivery</span></li>
                      <li><i class="bi bi-check"></i> <span>Personalización + Estadisticas</span></li>
                    </ul>
                    <div class="text-center"><button class="buy-btn">CONSULTAR</button></div>
                  </div>
                </div>

              </div>

            </div>

            </section>

</div>
      </section>
      : <></>
  )
}