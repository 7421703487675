
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../context/AppContext";
import { Link, useParams } from 'react-router-dom';
import { useCookies } from "react-cookie";


export default function PromocionEditar(props) {

  const { id } = useParams();
  const [registroExitoso, setRegistroExitoso] = useState(false);
  const { active, api } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [usuario] = useCookies(['usuario']);
  const [values, setValues] = useState({ 
    id: '',
    nombre: '',
    codigo: '',
    porcentaje: '',
    monto: '',
    minimo: '',
    comentario: '',
    capacidad: '',
    inicio: '',
    fin: '',
    idusuario: '',
   
  });
  useEffect(() => {

    async function fetchProducto() {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: id })
      };
  
      const response = await fetch(`${api}/api/promocion/promo`, requestOptions);
      const data = await response.json();
      setValues(data); 
      setLoading(false)
      
    }

    fetchProducto();
console.log(values.idcategoria)
   

  }, []);

  const handleSubmit =  async (event) =>{
    event.preventDefault();

    const formData = new FormData();
   formData.append('imgs', selectedImage); // Agrega la imagen al formData
  
   formData.append('id', values.id);
    formData.append('nombre', values.nombre);
    formData.append('codigo', values.codigo);
    formData.append('porcentaje', values.porcentaje);
    formData.append('monto', values.monto);
    formData.append('minimo', values.minimo);
    formData.append('comentario', values.comentario);
    formData.append('capacidad', values.capacidad);
    formData.append('inicio', values.inicio);
    formData.append('fin', values.fin);
    formData.append('idusuario', usuario.usuario.id);
   // formData.append('stock', values.stock);

    const getData = async () => {


      try {
        const response = await axios.post(`${api}/api/promocion/editar`, formData);
        console.log(response)
        if (response.status === 200 || response.status === 201) {
          setRegistroExitoso(true);
          //event.target.reset();
        }
      } catch (error) {
        console.log(error);
      }
    }


    await getData();
  };

  const handleChange = (event) => {
    const { name, value, type } = event.target;
    if (type === 'file') {
      setSelectedImage(event.target.files[0]);
    setValues((prevState) => ({ ...prevState, img: event.target.files[0] }));
    } else {
      setValues((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const formatDate = (dateValue) => {
    if (!dateValue) return ''; // Manejar el caso donde dateValue no está definido
  
    const date = new Date(dateValue);
  
    if (isNaN(date.getTime())) {
      // Si no es un valor de fecha válido, maneja el error o devuelve un valor por defecto
      return '';
    }
  
    return date.toISOString().split('T')[0];
  };

  return (
    <main id="main" class={active === 'active' ? 'main active' : 'main'}>

      <div class="pagetitle">
        <h1>Editar Promoción</h1>
        {/* <nav>
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="index.html">Home</a></li>
            <li class="breadcrumb-item">Forms</li>
            <li class="breadcrumb-item active">Elements</li>
          </ol>
        </nav> */}
      </div>
      {registroExitoso && (
        <div class="alert alert-success" role="alert">
          ¡Actualización exitosa!
        </div>
      )}
      <section class="section">
        <div class="row">
          <div class="col-lg-6">

            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Editar Promoción</h5>
                {loading ? (
          <div className="center">  <div className=" spinner"></div></div>
        
        ) :

                <form onSubmit={handleSubmit}>
                  <div class="row mb-3">
                    <label for="inputText" class="col-sm-2 col-form-label">Nombre</label>
                    <div class="col-sm-10">
                      <input type="text" class="form-control" name="nombre" value={values.nombre}
                        onSubmit={handleSubmit} onChange={handleChange} />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="inputEmail" class="col-sm-2 col-form-label">Código</label>
                    <div class="col-sm-10">
                      <input type="text" class="form-control" name="ingredientes" value={values.codigo}
                        onSubmit={handleSubmit} onChange={handleChange} />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="inputPassword" class="col-sm-2 col-form-label">Porcentaje</label>
                    <div class="col-sm-10">
                      <input type="number" class="form-control" name="precio" required 
                      value={values.porcentaje} onSubmit={handleSubmit} onChange={handleChange}/>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="formFile" class="col-sm-2 col-form-label">Tope Descuento</label>
                    <div class="col-sm-10">
                      <input type="number" class="form-control" name="precio" required 
                      value={values.monto} onSubmit={handleSubmit} onChange={handleChange}/>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="inputNumber" class="col-sm-2 col-form-label">Consumo Minimo</label>
                    <div class="col-sm-10">
                      <input type="number" class="form-control" name="stock"
                      value={values.minimo} onSubmit={handleSubmit} onChange={handleChange}/>
                    </div>
                    
                  </div>

                  <div class="row mb-3">
                    <label for="inputNumber" class="col-sm-2 col-form-label">Comentario</label>
                    <div class="col-sm-10">
                      <input type="number" class="form-control" name="stock"
                      value={values.comentario} onSubmit={handleSubmit} onChange={handleChange}/>
                    </div>
                    
                  </div>
                  <div class="row mb-3">
                    <label for="inputNumber" class="col-sm-2 col-form-label">Capacidad</label>
                    <div class="col-sm-10">
                      <input type="number" class="form-control" name="stock"
                      value={values.capacidad} onSubmit={handleSubmit} onChange={handleChange}/>
                    </div>
                    
                  </div>
                 
                  <div class="row mb-3">
                    <label for="inputNumber" class="col-sm-2 col-form-label">Fecha Inicio</label>
                    <div class="col-sm-10">
                    <input 
                            type="date" 
                            class="form-control" 
                            name="inicio"
                            value={formatDate(values.inicio)} 
                            onChange={handleChange}
                          />
                    </div>
                   
                  </div>
                  <div class="row mb-3">
                    <label for="inputNumber" class="col-sm-2 col-form-label">Fecha Fin</label>
                    <div class="col-sm-10">
                    <input 
                          type="date" 
                          class="form-control" 
                          name="fin"
                          value={formatDate(values.fin)} 
                          onChange={handleChange}
                        />
                    </div>
                    
                  </div>
                 
                 
                  <div class="text-center">
                    <button type="submit" class="btn btn-primary rounded-pill">
                      Actualizar
                    </button>
                    
                    <Link to="/Admin/productos">
                    <button  class="btn btn-primary rounded-pill">
                      Volver
                    </button></Link>
                  </div>

                </form>
                 }

              </div>
            </div>

          </div>

        </div>
      </section>

    </main>

  )

}
