
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../context/AppContext";
import { useParams } from 'react-router-dom';
import { useCookies } from "react-cookie";


export default function Img() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [registroExitoso, setRegistroExitoso] = useState(false);
  const { active, api } = useContext(AppContext);
  const [values, setValues] = useState('');
  const [usuario] = useCookies(['usuario']);
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();

    formData.append('imgs', event.target.img.files[0]);

    const getData = async () => {


      try {
        const response = await axios.post(`${api}/api/admin/upimg`, formData);
        console.log(response)
        if (response.status === 200 || response.status === 201) {
          setRegistroExitoso(true);
          event.target.reset();
        }
      } catch (error) {
        console.log(error);
      }
    }


    await getData();

  };
  



  return (
    <main id="main" class={active === 'active' ? 'main active' : 'main'}>

      <div class="pagetitle">
        {/* <nav>
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="index.html">Home</a></li>
            <li class="breadcrumb-item">Forms</li>
            <li class="breadcrumb-item active">Elements</li>
          </ol>
        </nav> */}
      </div>
      {registroExitoso && (
        <div class="alert alert-success" role="alert">
          ¡Actualización exitosa!
        </div>
      )}
      <section class="section">
        <div class="row">
          <div class="col-lg-6">

            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Img</h5>


                <form  onSubmit={handleSubmit}>
             
                  <div class="row mb-3">
                    <label for="formFile" class="col-sm-2 col-form-label">Imagén</label>
                    <div class="col-sm-10">
                      <input class="form-control" type="file" id="formFile" name="img" accept="image/*" required />
                    </div>
                  </div>                
                 
                  <div class="text-center">
                    <button type="submit" class="btn btn-primary rounded-pill">
                      Actualizar
                    </button>
                  </div>

                </form>

              </div>
            </div>

          </div>

        </div>
      </section>

    </main>

  )

}
