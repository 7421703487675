
import axios from "axios";
import React, { useContext, useState } from "react";
import { useCookies } from "react-cookie";
import AppContext from "../../context/AppContext";


export default function PromocionAlta() {

  const { active, api } = useContext(AppContext);
  const [registroExitoso, setRegistroExitoso] = useState(false);
  const [usuario] = useCookies(['usuario']);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const user = {
      nombre: formData.get('titulo'),
      comentario: formData.get('comentario'),
      inicio: formData.get('inicio'),
      fin: formData.get('fin'),
      capacidad: formData.get('capacidad'),
      codigo: formData.get('codigo'),
      porcentaje: formData.get('porcentaje'),
      monto: formData.get('monto'),
      idusuario: usuario.usuario.id,
    };
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(user),
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const response = await fetch(`${api}/api/promocion/alta`, requestOptions);
    const data = await response.status;
    console.log(data);

    // Si la respuesta indica que el registro fue exitoso,
    // actualizamos la variable de estado para mostrar el mensaje.
    if (data === 200) {
      setRegistroExitoso(true);
      event.target.reset();
    }
  };
  return (
    <main id="main" class={active === 'active' ? 'main active' : 'main'}>

      {/* <div class="pagetitle">
  <nav>
    <ol class="breadcrumb">
      <h2>Gestión Mesa</h2>
    </ol>
  </nav>
</div> */}

      {registroExitoso && (
        <div class="alert alert-success" role="alert">
          ¡Registro exitoso!
        </div>
      )}
      <section class="section">
        <div class="row">
          <div class="col-lg-8">

            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Alta Promoción</h5>


                <form onSubmit={handleSubmit}>
                  <div class="row mb-3">
                    <label for="inputText" class="col-sm-2 col-form-label">Titulo</label>
                    <div class="col-sm-5">
                      <input type="text" class="form-control" name="titulo" required />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="inputText" class="col-sm-2 col-form-label">Código</label>
                    <div class="col-sm-5">
                      <input type="text" class="form-control" name="codigo" required />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="inputText" class="col-sm-2 col-form-label">Porcentaje</label>
                    <div class="col-sm-5">
                      <input type="number" class="form-control" name="porcentaje" required />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="inputText" class="col-sm-2 col-form-label">Monto Tope</label>
                    <div class="col-sm-5">
                      <input type="number" class="form-control" name="monto" required />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="inputPassword" class="col-sm-2 col-form-label">Inicio</label>
                    <div class="col-sm-5">
                      <input type="date" class="form-control" name="inicio" required />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="inputPassword" class="col-sm-2 col-form-label">Fin</label>
                    <div class="col-sm-5">
                      <input type="date" class="form-control" name="fin" required />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="inputNumber" class="col-sm-2 col-form-label">Capacidad</label>
                    <div class="col-sm-5">
                      <input type="number" class="form-control" name="capacidad" required />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="inputEmail" class="col-sm-2 col-form-label">Comentario</label>
                    <div class="col-sm-5">
                      <textarea type="text" class="form-control" name="comentario"></textarea>
                    </div>
                  </div>
                  {/* <div class="row mb-3">
              <label for="inputDate" class="col-sm-2 col-form-label">Plan</label>
              

              <div class="col-sm-5">
                <select class="form-select" aria-label="Default select example">
                  <option selected="">Open this select menu</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
            </div>*/}
                  <div class="text-center">
                    <button type="submit" class="btn btn-primary rounded-pill">
                      Registrar
                    </button>
                  </div>

                </form>

              </div>
            </div>

          </div>

        </div>
      </section>

    </main>

  )

}