import React, { useContext, useState, useEffect } from 'react';
import { Link, Redirect, useHistory, redirect, useNavigate } from "react-router-dom";
import AppContext from '../context/AppContext';
import { useCookies } from 'react-cookie';


export default function ProductoItemAdmin({ props }) {

  const { addToCart, cart, deleteCartById, restToCart, link,  setClick, deleteCart, pedidos, api } = useContext(AppContext);
  const [usuario, setUsuario] = useCookies(['usuario']);
  //const [showError, setShowError] = useState(false);
  const [cookies, setCookie] = useCookies(['cliente']);
  const [isLoading, setIsLoading] = useState(false);
  const [ok, setOk] = useState(0);
  const navigate = useNavigate();
  const [comentario, setComentario] = useState('');
  var modal = document.getElementById('exampleModal');
  const [selectedOptionPago, setSelectedOptionPago] = useState([]);
  const mesa = 0;

  useEffect(() => {
    const handleBackButton = (event) => {
      const modal = document.getElementById(`carrito`);

      if(  modal.classList.contains('active')){
      modal.classList.remove('active');
    
        setClick();
      }
    
    };

   // win9dow.history.pushState(null, '', window.location.href); // Agrega una nueva entrada de historial para evitar volver atrás
    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  const hideModal = () => {
    // const modal = document.querySelector('.modal'); // obtener el modal
    const backdrop = document.querySelector('.modal-backdrop'); // obtener el backdrop
if (modal){

    modal.classList.remove('show'); // ocultar el modal
    modal.classList.remove('hide');
          modal.style.display = 'none';
          document.body.classList.remove('modal-open');

          document.body.style.removeProperty('overflow');
          document.body.style.removeProperty('padding-right');
}
    if (backdrop) {
      backdrop.remove(); // eliminar el backdrop
    }
  };

  const [nombre, setNombre] = useState(cookies.cliente?.nombre || '');
  const [email, setEmail] = useState(cookies.cliente?.email || '');
  const [telefono, setTelefono] = useState(cookies.cliente?.telefono || '');



  const sumaTotal = () => {
    const reducer = (accumalator, currentValue) => accumalator + currentValue.price * currentValue.qty;
    const sum = cart.card.reduce(reducer, 0)
    return sum;
  }

  let qty = 0;

  const revomeProdu = produ => {
    deleteCartById(produ);

  }

  const handleOptionChange = (opcion) => {
    setSelectedOptionPago(opcion);
    console.log(selectedOptionPago)
  };


  const addCliente = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const user = {
      nombre: formData.get('name'),
      email: formData.get('email'),
      telefono: formData.get('telefono'),
      subscrito: formData.get('subscrito'),
    };

    setCookie('cliente', {
      nombre: user.nombre,
      telefono: user.telefono.toString(),
      email: user.email,
      subscrito: user.subscrito,
    }, { path: '/', maxAge: 2592000 });
    //,  consumoTotal: consumoTotal 

    setIsLoading(true);
    try {
      const data = {
        cliente: { nombre: user.nombre, email: user.email, telefono: user.telefono, subscrito: user.subscrito },
      };


      const response = await fetch(`${api}/api/pedidos/cliente`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },

        body: JSON.stringify(data),
      });
      const datas = await response.status;

      if (datas === 200) {
        setOk(datas)
        const modal = document.getElementById(`carrito`);

        modal.classList.remove('active');
      
        setClick();
        
        updateProduct(cart.card, sumaTotal(), data ,selectedOptionPago);
        hideModal(); // cerrar el modal
      }

    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false); // desactivar el indicador de carga
    }
  }

  const updateProduct = async (products, consumoTotal, dato, metapago) => {
    // event.preventDefault(); // Evita que se envíe la solicitud por defecto del navegador


    setIsLoading(true); // activar el indicador de carga

    try {
      const data = {
        producto: products.map(producto => {
          return { id: producto.id, cantidad: producto.qty, name: producto.nombre, precio: producto.price, comentario: producto.comentario };
        }),
        pedidos: { consumototal:  consumoTotal, comentario: dato.cliente.comentario, pago: metapago },
        cliente: { nombre: dato.cliente.nombre, email: dato.cliente.email, telefono: dato.cliente.telefono },
      };

      const response = await fetch(`${api}/api/pedidos/${usuario.usuario.id}/${mesa}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },

        body: JSON.stringify(data),
      });
      const datas = await response.status;
      const pedido = await response.json();
      console.log(pedido);
      if (datas === 200) {
        pedidos(datas);
        deleteCart();
        setOk(datas)
        setClick();
        hideModal();
      }

    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false); // desactivar el indicador de carga
    }
  };

  return (
    <>
      {
        cart.card.map((props) => (

          <li id="cart" className="confirm">
            <div className="photo"><img src={props.img} alt="" loading="lazy"/></div>
            <div className="boxes" >
              <div className="detalles name">
                <h6>{props.nombre}</h6>
              </div>
              <div className="detalles interno">
                <div className='qty'>
                  <span onClick={() => {
                    if (props.qty == 1) return;
                    // setShowError(true);
                    restToCart(props, -1);
                  }}
                    // disabled={qty - props.qty <= 0 ? true :  false} 
                    className="material-symbols-outlined">
                    remove_circle_outline
                  </span>

                  <span id="cantidad">{props.qty} </span>

                  {/* onClick={() => addProduct(-1)}
                         disabled={qty === initial ? true : null} */}

                  <span className="material-symbols-outlined"
                    onClick={() => {
                      if (props.qty === props.stock) return;
                      addToCart(props, 1);


                    }} disabled={props.stock - qty === 0 ? true : null}>
                    add_circle_outline
                  </span>

                </div>

                <h6 className="precio">${(props.price * props.qty).toFixed(2)}</h6>
                <div className="delete" onClick={() => revomeProdu(props)} >
                  <span className="material-symbols-outlined" translate="no">
                    highlight_off
                  </span>
                </div>
              </div>
              { //showError && <p className='error-message'>No se puede restar más del stock disponible</p>
              }
            </div>

          </li>))}
      {/* <li className='center'>   <div className="accordion accordion-flush" id="accordionFlushExample">
            <br></br>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button className="accordion-button collapsed pad10" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                          Agregar comentario
                        </button>
                      </h2>
                      <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body"><textarea className="form-control" name="message" rows="3" placeholder="Comentario" onChange={(e) => setComentario(e.target.value)}></textarea></div>
                      </div>
                    </div>
                  </div> </li> */}
      <ul>
        <li id="comprar">
          <h4 className="subtotal">Subtotal</h4>
          <h4 className="subtotal cartTotal">${sumaTotal().toFixed(2).slice(0, -3)}</h4>
          <h6 className="decimal">{sumaTotal().toFixed(2).slice(-2)}</h6>

          <div id="comprar" className="duo">

            { cookies.cliente && cookies.cliente.nombre ?
              isLoading ? <button className="normal end second"><div className="loading d-block"></div></button> :
                <button type="button" className="normal end"><div className="dropup-center dropup">
                  <h3 className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Realizar Pedido</h3>
                  <ul className="dropdown-menu metopago">
                    <li> <button className="dropdown-item primaryMenu" onClick={() => updateProduct(cart.card, sumaTotal(), cookies, 'EFEC')}><i className="bi bi-coin"></i>Eféctivo</ button></li>
                    <li> <button className="dropdown-item primaryMenu" onClick={() => updateProduct(cart.card, sumaTotal(), cookies, 'TARJ')}><i className="bi bi-credit-card-2-front"></i>Débito/Crédito</ button></li>
                    {/* <li> <button className="dropdown-item"   onClick={() => setSelectedOptionPago({  pago: 'DEBI', idusuario: usuario.usuario.id})}>Débito</ button></li> */}
                    <li><hr className="dropdown-divider"></hr></li>
                    <li> <button className="dropdown-item primaryMenu" onClick={() => updateProduct(cart.card, sumaTotal(), cookies, 'MEPG')}><svg width="27" height="27" stroke="black" stroke-width="2" viewBox="0 0 48 48"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="M40.976 30.646a5.276 5.276 0 0 1-2.172-2.034a54.661 54.661 0 0 1-8.748 1.017c-3.7 0-6.687-.176-5.467-3.624s4.458-10.556 5.593-11.986s2.686-3.24 3.449-3.155c.946.106 2.715 1.284 2.516 2.034c-.189.715-1.128 2.277-2.747 1.165" /><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="M32.864 14.84a8.134 8.134 0 0 1 1.293-1.641m1.783.207c.567-.144 1.64.582 1.034 1.328a4.778 4.778 0 0 1-2.817 1.324c-.625.056-2.797-.02-2.797-.02c-.925 1.597-.715 4.037-.8 6.182a9.346 9.346 0 0 1-.882 3.659c3.7-1.725 10.009-3.029 13.822-2.398M7.024 30.646a5.276 5.276 0 0 0 2.172-2.034a54.661 54.661 0 0 0 8.748 1.017c3.7 0 6.687-.176 5.467-3.624s-4.458-10.556-5.593-11.986s-2.686-3.24-3.449-3.155c-.946.106-2.715 1.284-2.516 2.034c.189.715 1.128 2.277 2.747 1.165" /><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="M15.136 14.84a8.134 8.134 0 0 0-1.292-1.641m-1.784.207c-.567-.144-1.64.582-1.034 1.328a4.778 4.778 0 0 0 2.818 1.324c.624.056 2.796-.02 2.796-.02c.925 1.597.715 4.037.8 6.182a9.346 9.346 0 0 0 .882 3.659C14.622 24.153 8.313 22.85 4.5 23.48m27.681-11.738a27.566 27.566 0 0 0-16.364 0" /><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="M10.917 13.99C6.976 16.46 4.5 20.03 4.5 24c0 7.456 8.73 13.5 19.5 13.5S43.5 31.456 43.5 24c0-3.97-2.476-7.54-6.417-10.01" /></svg> Mercado Pago</ button>
                    </li>
                  </ul>
                </div>
                </button>
              : <button className="normal end second" id="add" data-bs-toggle="modal" data-bs-target='#PedidoModal'>
                <h3>Terminar Pedido</h3>
              </button>}
             <button className="normal end" id="add"  onClick={setClick} >
              <h4>Volver a Menú</h4>
            </button>



          </div>
        </li></ul>

      <div className="modal fade" id={`PedidoModal`} tabIndex="-1" aria-labelledby={`PedidoModal`}  >
        <div className="modal-dialog">
          <div className="modal-content modal-contentMenu">
            <div className="modal-header">
              <span data-bs-dismiss="modal" className="btn-close material-icons-outlined" translate="no">close</span>

            </div>
            <div className="modal-body modal-bodyMenu">

              <form onSubmit={(event) => addCliente(event)}>
                <div className="row">
                  <h5>Datos para mejorar tu experiencia</h5>
                  <div className=" form-group">
                    <input type="text" name="name" className="form-control" id="name" placeholder="Tu Nombre" value={nombre} onChange={(e) => setNombre(e.target.value)} required />
                  </div>
                </div>  
                <div className=" form-group">
                  <input type="number" className="form-control" name="telefono" id="celular" placeholder="Tu Celular" value={telefono} pattern="[0-9]{1,10}" onChange={(e) => setTelefono(e.target.value)} required />
                </div>
                <div className="form-group mt-3">
                  <input type="email" className="form-control" name="email" id="email" placeholder="Tu Email (opcional)" value={email} onChange={(e) => setEmail(e.target.value)}  />

                </div>
              
                {/* <div className="form-group mt-3">
                  <textarea className="form-control" name="comentario" rows="2" placeholder="Observación" ></textarea>
                </div> */}
                <div className="my-3">

                  <input type="checkbox" name="subscrito"  />
                  <h6>Subscribirme para recibir promociones</h6>

                </div>

                {/* <div >
                <div className="loading d-block"></div>
                <div className="error-message"></div>
              </div> */}

                {ok === 200 ? <div className='center' data-bs-dismiss="modal"><i className="bi bi-check-circle-fill"></i></div> :
                 isLoading ? <button className="normal end second"><div className="loading d-block"></div></button> :
                 <button type="button" className="normal end"><div className="dropup-center dropup">
                   <h3 className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Pedir</h3>
                   <ul className="dropdown-menu metopago">
                     <li> <button className="dropdown-item primaryMenu" onClick={() => handleOptionChange('EFEC')}><i className="bi bi-coin"></i>Eféctivo</ button></li>
                     <li> <button className="dropdown-item primaryMenu" onClick={() => handleOptionChange('TARJ')}><i className="bi bi-credit-card-2-front"></i>Débito/Crédito</ button></li>
                     {/* <li> <button className="dropdown-item"   onClick={() => setSelectedOptionPago({  pago: 'DEBI', idusuario: usuario.usuario.id})}>Débito</ button></li> */}
                     <li><hr className="dropdown-divider"></hr></li>
                     <li> <button className="dropdown-item primaryMenu" onClick={() => handleOptionChange('MEPG')}><svg width="27" height="27" stroke="black" stroke-width="2" viewBox="0 0 48 48"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="M40.976 30.646a5.276 5.276 0 0 1-2.172-2.034a54.661 54.661 0 0 1-8.748 1.017c-3.7 0-6.687-.176-5.467-3.624s4.458-10.556 5.593-11.986s2.686-3.24 3.449-3.155c.946.106 2.715 1.284 2.516 2.034c-.189.715-1.128 2.277-2.747 1.165" /><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="M32.864 14.84a8.134 8.134 0 0 1 1.293-1.641m1.783.207c.567-.144 1.64.582 1.034 1.328a4.778 4.778 0 0 1-2.817 1.324c-.625.056-2.797-.02-2.797-.02c-.925 1.597-.715 4.037-.8 6.182a9.346 9.346 0 0 1-.882 3.659c3.7-1.725 10.009-3.029 13.822-2.398M7.024 30.646a5.276 5.276 0 0 0 2.172-2.034a54.661 54.661 0 0 0 8.748 1.017c3.7 0 6.687-.176 5.467-3.624s-4.458-10.556-5.593-11.986s-2.686-3.24-3.449-3.155c-.946.106-2.715 1.284-2.516 2.034c.189.715 1.128 2.277 2.747 1.165" /><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="M15.136 14.84a8.134 8.134 0 0 0-1.292-1.641m-1.784.207c-.567-.144-1.64.582-1.034 1.328a4.778 4.778 0 0 0 2.818 1.324c.624.056 2.796-.02 2.796-.02c.925 1.597.715 4.037.8 6.182a9.346 9.346 0 0 0 .882 3.659C14.622 24.153 8.313 22.85 4.5 23.48m27.681-11.738a27.566 27.566 0 0 0-16.364 0" /><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="M10.917 13.99C6.976 16.46 4.5 20.03 4.5 24c0 7.456 8.73 13.5 19.5 13.5S43.5 31.456 43.5 24c0-3.97-2.476-7.54-6.417-10.01" /></svg> Mercado Pago</ button>
                     </li>
                   </ul>
                 </div>
                 </button>
                }
              </form>

            </div>

          </div></div>

      </div>

    </>
  )

}