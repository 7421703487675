import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useParams,
  useNavigate,
} from "react-router-dom";
//import { render } from "react-dom";
import React, { useEffect } from "react";
import useInitialState from "../hooks/useInitialState";
import { useContext, useState } from "react";
import "../vendor/animate.css/animate.css";
import "../vendor/bootstrap/css/bootstrap.css";
import "../vendor/bootstrap-icons/bootstrap-icons.css";
import "../vendor/boxicons/css/boxicons.css";
import "../vendor/glightbox/css/glightbox.css";
import '../css/MenuApp.css';
import AppContext from "../context/AppContext";
import Home from "../page/home";
import Menu from "../page/menu";
import Especial from "../page/especial";
import Contact from "../page/contact";
import Evento from "../page/evento";
import Cart from "../page/cart";
import { useCookies } from "react-cookie";
import ErrorMenu from "../components/errorMenu";
import HomeClickMenu from "../page/homeClickMenu";
import Locales from "../page/locales";
import Ubicacion from "../page/ubicacion";
import CartSucess from "../page/cartSucess";
import Subcripcion from "../page/subcripcion";
import PedidosMenu from "../page/pedidosMenu";
import { notification } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

export default function MenuApp() {
  const { api, modo, handleLoginSuccess, setTable, table, setModo } =
    useContext(AppContext);
  const initialState = useInitialState();
  const [usuario, setUsuario] = useCookies(["usuario"]);
  const [mesero, setMesero] = useCookies(["mesero"]);
  const { cart } = useContext(AppContext);
  const [active, setActive] = useState("inicio");
  const { id } = useParams();
  const [cookies, setCookie] = useCookies(["cliente"]);
  const [isLoading, setIsLoading] = useState(false);
  const [ok, setOk] = useState(0);
  const navigate = useNavigate();
  const [nombre, setNombre] = useState(cookies.cliente?.nombre || "");
  const [email, setEmail] = useState(cookies.cliente?.email || "");
  const [telefono, setTelefono] = useState(cookies.cliente?.telefono || "");
  const [activeButton, setActiveButton] = useState("button2");
  const [direccion, setDireccion] = useState(
    cookies.cliente?.direcciones?.find((dir) => dir.activa) || ""
  );
  const [mesa, setMesa] = useState("");
  const [registroExitoso, setRegistroExitoso] = useState();
  const [data, setData] = useState(false);
  const [selectedMesaId, setSelectedMesaId] = useState(null);
  const registroString = localStorage.getItem('registroExitosoMesero');
  useEffect(() => {

    
    if (!registroString) {
      localStorage.removeItem('registroExitosoMesero'); 
     }
     if (registroString) {
     const { value, expirationTime } = JSON.parse(registroString);  
    const currentTime = new Date().getTime();
    
      if (currentTime > expirationTime) {
        localStorage.removeItem('registroExitosoMesero'); // Eliminar el valor expirado
       
      }else{
        fetchMesas();
      }
      
    }
  }, [registroString]);
  
  const fetchMesas = async () => {
    try {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };
  
      const response = await fetch(`${api}/api/mesero/${usuario?.usuario.id}`, requestOptions);
  
      if (response.ok) {
        const data = await response.json();
        setData(data);
        setRegistroExitoso(true);
      } else {
        handleLoginSuccess(false);
        setRegistroExitoso(false);
      }
    } catch (error) {
      console.error("Error fetching mesas:", error);
      handleLoginSuccess(false);
      setRegistroExitoso(false);
    }
  };
  

  const handleMesaClick = (mesa) => {
    setSelectedMesaId(mesa.id);
    setMesa(mesa);
    setTable(mesa.mesa);
  };

  const handleClick = (item) => {
    setActive(item);
  };
  const handleClickbttn = (buttonId) => {
    setActiveButton(buttonId);
  };

  const handleRegistration = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    let user = { idusuario: usuario.usuario.id };
    if (formData.get("email")) {
      user = {
        email: formData.get("email"),
        password: formData.get("password"),
      };
    } else {
      setCookie(
        "cliente",
        {
          nombre: cookies.cliente?.nombre || formData.get("nombre"),
          telefono: cookies.cliente?.telefono|| formData.get("telefono")?.toString(),
        },
        { path: "/", maxAge: 2592000 }
      );
    }
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(user),
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(`${api}/api/mesero/login`, requestOptions);
    const dataPromise = await response.json();
    if (response.ok) {
     
      setData(dataPromise);
     
     

      if (dataPromise.mesero) {
         setRegistroExitoso(true);
         setModo("Mozo " + dataPromise.mesero  )
        setMesero("mesero", dataPromise.mesero, {
          path: "/",
          maxAge: 840 * 60,
        });
        const expirationTime = new Date().getTime() + 12 * 60 * 60 * 1000; // 4 horas en milisegundos
        localStorage.setItem(
          "registroExitosoMesero",
          JSON.stringify({ value: true, expirationTime })
        );
      }
    } else {
 

      notification.open({
        message: "Notificación",
        description: dataPromise.message,
        icon: <CloseCircleOutlined style={{ color: "#bd0003" }} />,
        duration: 4,
      });
      handleLoginSuccess(false);
      setRegistroExitoso(false);
    }
  };

  const socioClick = () => {
    localStorage.setItem("Admin", true);
    window.location.replace("/Admin/login");
  };

  const currentUrl = window.location.href;
  const isWordInUrl = currentUrl.includes("error");

  const fetchUsuario = () => {
    try {
      const response = fetch(`${api}/api/usuarios/name/ClickMenu`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name: "ClickMenu" }), // Datos a enviar en el cuerpo de la solicitud
      });
      const data = response.json();
      setUsuario("usuario", data, { path: "/", maxAge: 604800 });
    } catch (error) {
      console.log("Error al obtener el usuario:", error);
    }
  };

  const addCliente = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const user = {
      nombre: formData.get("name"),
      email: formData.get("email"),
      telefono: formData.get("telefono"),
      subscrito: formData.get("subscrito"),
    };

    setCookie(
      "cliente",
      {
        nombre: user.nombre,
        telefono: user.telefono.toString(),
        email: user.email,
        subscrito: user.subscrito,
      },
      { path: "/", maxAge: 2592000 }
    );
    //,  consumoTotal: consumoTotal

    setIsLoading(true);
    try {
      const data = {
        cliente: {
          nombre: user.nombre,
          //email: user.email,
          telefono: user.telefono,
          subscricion: user.subscrito,
        },
      };

      const response = await fetch(`${api}/api/pedidos/cliente`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify(data),
      });
      const datas = await response.status;

      if (datas === 200) {
        setOk(datas);
        //hideModal(); // cerrar el modal
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false); // desactivar el indicador de carga
    }
  };

  return (
    <div className="bodyMenu">
      <div id="topbar" className="d-flex align-items-center fixed-top">
        <div className="container d-flex justify-content-center justify-content-md-between">
          <div className="contact-info d-flex align-items-center">
            {/*  
             <i
              className="bi bi-clock d-flex align-items-center ms-4"
              data-bs-target={`#exampleModal-MESA`}
              data-bs-toggle="modal"
              id="menumodal"
            > </i> */}
           {  usuario?.usuario?.local === "ClickMenu" ?<a
                className="d-flex align-items-center"
                href={`https://wa.me/+549${usuario.usuario.whatsapp}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="bi bi-phone" style={{ marginRight: '8px' }}></i>
                <span className="white">CONTACTANOS</span>
              </a> :  <i
              class="bi bi-circle-square d-flex align-items-center ms-4 mr-2"
              data-bs-target={`#exampleModal-MESA`}
              data-bs-toggle="modal"
            >
              {!table ? <span>SELECCIONE MESA</span> : <span>MESA <strong>{table}</strong></span>}
            </i>}
          </div>

          {/* <div className="languages d-none d-md-flex align-items-center">
            <ul>
              <li>En</li>
              <li><a>In</a></li>
            </ul>
          </div> */}
        </div>
      </div>

      <header id="headerMenu" className="fixed-top d-flex align-items-cente">
        {usuario?.usuario || isWordInUrl ? (
          <div className="container-fluid container-xl d-flex align-items-center justify-content-lg-between">
            <h1 className="logoo me-auto me-lg-0">
              <Link
                className="nav-link scrollto active"
                to={
                  usuario?.usuario?.local === "ClickMenu"
                    ? `/`
                    : `/${usuario?.usuario?.local}`
                }
              >
                {usuario?.usuario?.local}
              </Link>{" "}
            </h1>

            <a className="logoo me-auto me-lg-0">
              <img src="" alt="" className="img-fluid" />
            </a>

            <nav className="navbar order-last order-lg-0">
              <ul id="navbar" className={active === "active" ? "active" : ""}>
                <li>
                  <Link
                    className={
                      active === "inicio"
                        ? "nav-link scrollto active"
                        : "nav-link scrollto"
                    }
                    onClick={() => handleClick("inicio")}
                    to={`/${usuario?.usuario?.local}`}
                  >
                    Inicio
                  </Link>
                </li>
                {usuario?.usuario?.local === "ClickMenu" ? (
                  <li>
                    <Link
                      className={
                        active === "menu"
                          ? "nav-link scrollto active"
                          : "nav-link scrollto"
                      }
                      to={`/Locales`}
                      onClick={() => handleClick("menu")}
                    >
                      Locales
                    </Link>
                  </li>
                ) : (
                  <li>
                    <Link
                      className={
                        active === "menu"
                          ? "nav-link scrollto active"
                          : "nav-link scrollto"
                      }
                      to={`${usuario?.usuario?.local}/menu`}
                      onClick={() => handleClick("menu")}
                    >
                      Menú
                    </Link>
                  </li>
                )}

                <li>
                  <Link
                    className={
                      active === "contacto"
                        ? "nav-link scrollto active"
                        : "nav-link scrollto"
                    }
                    to={`${usuario?.usuario?.local}/contacto`}
                    onClick={() => handleClick("contacto")}
                  >
                    Contacto
                  </Link>
                </li>
                <li>
                  <Link onClick={() => socioClick()}>
                    <h6>
                      <i class="bi bi-cursor-fill"></i>Aliados
                    </h6>
                  </Link>
                </li>
                <a id="close" onClick={() => handleClick("")}>
                  <span className="material-icons-outlined">close</span>
                </a>
              </ul>
              <i
                onClick={() => handleClick("active")}
                className="bi bi-list mobile-nav-toggle"
              ></i>
            </nav>
            {usuario?.usuario?.local === "ClickMenu" ? (
              <a
                onClick={() => socioClick()}
                className="book-a-table-btn scrollto d-none d-lg-flex"
              >
                ALIADOS
              </a>
            ) : (
              <Link className="book-a-table-btn scrollto d-none d-lg-flex"
              to={`${usuario?.usuario?.local}/pedidos`}
              > Mis Pedidos</Link>
              
            )
            
            }
          </div>
        ) : (
          <div id="preloader"></div>
        )}
      </header>

      <>
        <div
          className="modal fade"
          id={`exampleModal-MESA`}
          tabIndex="-1"
          aria-labelledby={`exampleModalLabel-MESA`}
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content modal-contentMenu">
              <div className="modal-header">
                <span
                  data-bs-dismiss="modal"
                  className="btn-close material-icons-outlined"
                  translate="no"
                >
                  close
                </span>
              </div>

              <div className="modal-body modal-bodyMenu">
                <div
                  className="accordion accordion-flush"
                  id={`accordionFlushExample-MESA`}
                >
                  <div className="gslide-media gslide-image">
                    <img
                      loading="lazy"
                      className="zoomable imgModal"
                      data-bs-toggle="modal"
                      data-bs-target={`#exampleModal-MESA`}
                    />
                  </div>

                  <div className="center colorprimary">
                    <center className="grid">
                      <div>
                        <div className="btn-group">
                          <button
                            type="button"
                            className={`btn ${
                              activeButton === "button1"
                                ? "btn-primaryMenu"
                                : "btn-secondary sombra"
                            }`}
                            onClick={() => handleClickbttn("button1")}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-person-arms-up"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                              <path d="m5.93 6.704-.846 8.451a.768.768 0 0 0 1.523.203l.81-4.865a.59.59 0 0 1 1.165 0l.81 4.865a.768.768 0 0 0 1.523-.203l-.845-8.451A1.5 1.5 0 0 1 10.5 5.5L13 2.284a.796.796 0 0 0-1.239-.998L9.634 3.84a.7.7 0 0 1-.33.235c-.23.074-.665.176-1.304.176-.64 0-1.074-.102-1.305-.176a.7.7 0 0 1-.329-.235L4.239 1.286a.796.796 0 0 0-1.24.998l2.5 3.216c.317.316.475.758.43 1.204Z" />
                            </svg>
                            Comensal
                          </button>
                          <button
                            type="button"
                            className={`btn ${
                              activeButton === "button2"
                                ? "btn-primaryMenu"
                                : "btn-secondary sombra"
                            }`}
                            onClick={() => handleClickbttn("button2")}
                          >
                            <i class="bi bi-rocket-takeoff"></i> Mozo
                          </button>
                        </div>
                      </div>
                      {activeButton === "button1" && (
                        <>
                          <div className="modal-body modal-bodyMenu">
                            {(cookies?.cliente && !data )? (
                              <form onSubmit={handleRegistration}>
                               
                                 {cookies.cliente ?<h6> Hola {cookies.cliente.nombre}! 😃 </h6>: <div className="row"><h5 className="mb-4">
                                    Datos para mejorar tu experiencia
                                  </h5> 
                                 <div className=" form-group">
                                    <input
                                      type="text"
                                      name="nombre"
                                      className="form-control"
                                      id="nombre"
                                      placeholder="Tu Nombre"
                                      value={nombre}
                                      onChange={(e) =>
                                        setNombre(e.target.value)
                                      }
                                      required
                                    />
                                  </div>
                                <div className=" form-group">
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="telefono"
                                    id="celular"
                                    placeholder="Tu Celular"
                                    value={telefono}
                                    pattern="[0-9]{1,10}"
                                    onChange={(e) =>
                                      setTelefono(e.target.value)
                                    }
                                    required
                                  />
                                </div>
                                </div> }

                                <div className="text-center d-block flex center">
                                  <button className="buttonMenu" type="submit">
                                    <i class="bi bi-collection"></i> Elegir Mesa
                                  </button>
                                </div>
                              </form>
                            ) : (
                              <div className="card-body">
                                <div className=" pb-3">
                                  <h6 className="text-center ">
                                    {selectedMesaId === mesa.id
                                      ? `Elegiste Mesa ${mesa.mesa}. Capacidad para ${mesa.capacidad}`
                                      : "Seleccione una mesa"}
                                  </h6>
                                </div>
                                {data.mesas &&  data.mesas.map((mesa, index) => (
                                  <div
                                    key={index}
                                    className={`tablesmall ${
                                      selectedMesaId === mesa.id ? "active" : ""
                                    }`}
                                    onClick={() => handleMesaClick(mesa)}
                                  >
                                    <h4>{mesa.mesa}</h4>
                                  </div>
                                ))}
                                {selectedMesaId && (
                                  <Link
                                    to={`/${usuario?.usuario?.local}/menu/Local`}
                                    className="pt-4"
                                  >
                                    <button
                                      className="buttonMenu mt-4"
                                      data-bs-dismiss="modal"
                                    >
                                      <i class="bi bi-collection"></i> Elegir
                                      Menú
                                    </button>
                                  </Link>
                                )}
                              </div>
                            )}
                          </div>
                        </>
                      )}
                      {activeButton === "button2" && (
                        <>
                          <div className="container">
                            <div className="row justify-content-center">
                              <div>
                                <div className=" mb-3">
                                  {(!registroString )? (
                                    <div className="card-body">
                                      <div className="pt-2 pb-2">
                                        <h6 className="text-center ">
                                          Ingresar tu email y contraseña para
                                          iniciar
                                        </h6>
                                      </div>

                                      <form
                                        className="row g-3 needs-validation"
                                        onSubmit={handleRegistration}
                                      >
                                        <div className="col-12">
                                          <input
                                            type="text"
                                            name="email"
                                            className="form-control"
                                            id="youremail"
                                            placeholder="Tu Email"
                                            required
                                            autoComplete="current-email"
                                          />
                                          <div className="invalid-feedback">
                                            ¡Por favor, ingresar tu email!
                                          </div>
                                        </div>

                                        <div className="col-12">
                                          <input
                                            type="password"
                                            name="password"
                                            className="form-control"
                                            id="yourPassword"
                                            pattern="^\d{6,}$"
                                            placeholder="Tu Contraseña"
                                            required
                                            autoComplete="current-password"
                                          />
                                          <div className="invalid-feedback">
                                            ¡Por favor, ingresar tu contraseña!
                                          </div>
                                        </div>

                                        {/* <div className="col-12">
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" name="remember" value="true" id="rememberMe"/>
                            <label className="form-check-label">Remember me</label>
                          </div>
                        </div> */}
                                        <div className="col-12">
                                          <button
                                            className="buttonMenu"
                                            type="submit"
                                          >
                                            Ingresar
                                          </button>
                                        </div>
                                        {/* <div className="col-12">
                        <p className="small mb-0">¿Necesitas confirmar una cuenta nueva?  <Link to="/Admin/registro">Ingresa aquí</Link></p>
                      </div> */}
                                      </form>
                                    </div>
                                  ) : (
                                    <div className="card-body">
                                      <div className=" pb-3">
                                        <h6 className="text-center ">
                                          {selectedMesaId === mesa.id
                                            ? `Elegiste Mesa ${mesa.mesa}. Capacidad para ${mesa.capacidad}`
                                            : "Seleccione una mesa"}
                                        </h6>
                                      </div>
                                      { data.mesas && data.mesas.map((mesa, index) => (
                                        <div
                                          key={index}
                                          className={`tablesmall ${
                                            selectedMesaId === mesa.id
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() => handleMesaClick(mesa)}
                                        >
                                          <h4>{mesa.mesa}</h4>
                                        </div>
                                      ))}
                                      {selectedMesaId && (
                                        <Link
                                          to={`/${usuario?.usuario?.local}/menu/Local`}
                                          className="pt-4"
                                        >
                                          <button
                                            className="buttonMenu mt-4"
                                            data-bs-dismiss="modal"
                                          >
                                            <i class="bi bi-collection"></i>{" "}
                                            Elegir Menú
                                          </button>
                                        </Link>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>

      <Routes>
        <Route path={`/`} element={<HomeClickMenu className="active" />} />
        <Route
          index
          path={`/ClickMenu`}
          element={<HomeClickMenu className="active" />}
        />
        <Route
          index
          path={`/Locales`}
          element={<Locales className="active" />}
        />
        <Route index path={`:id`} element={<Home className="active" />} />
        <Route
          exact
          path=":id/menu/:parametro?"
          element={<Menu className="active" />}
        />
        <Route
          exact
          path=":id/ubicacion"
          element={<Ubicacion className="active" />}
        />
        {/* <Route exact path=':id/especial' element={<Especial />} /> */}
        <Route exact path=":id/contacto" element={<Contact />} />
        <Route exact path=':id/sumarme' element={<Subcripcion />} /> 
        <Route exact path=":id/pago" element={<Cart />} />
        <Route exact path=":id/pedidos" element={<PedidosMenu />} />
        <Route exact path="/pago/estado" element={<CartSucess />} />
        <Route exact path="/error" element={<ErrorMenu />} />
        <Route path="*" element={<HomeClickMenu className="active" />} />
      </Routes>
      {usuario?.usuario ? (
        <footer id="footerMenu">
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div className="footer-info">
                    <h3>{usuario?.usuario?.local}</h3>
                    
                    {usuario.usuario.direccion && <p><strong>Dirección: </strong> {usuario.usuario.direccion} </p>}
                      <br />
                      {usuario.usuario.telefono && <p> <strong>Teléfono: </strong>{usuario.usuario.telefono}</p>}
                      <br />
                      {/* <strong>Email: </strong>{usuario.usuario.email}<br /> */}
                   
                    <div className="social-links mt-3">
                      {usuario.usuario.instagram ? (
                        <a
                          href={`https://www.instagram.com/${usuario.usuario.instagram}`}
                          target="_blank"
                          className="instagram"
                        >
                          <i className="bx bxl-instagram"></i>
                        </a>
                      ) : (
                        <></>
                      )}

                      {usuario.usuario.facebook ? (
                        <a
                          href={usuario.usuario.facebook}
                          target="_blank"
                          className="facebook"
                        >
                          <i className="bx bxl-facebook"></i>
                        </a>
                      ) : (
                        <></>
                      )}
                      {usuario.usuario.whatsapp ? (
                        <a
                          href={`https://wa.me/+549${usuario.usuario.whatsapp}`}
                          target="_blank"
                          className="whatsapp"
                        >
                          <i className="bx bxl-whatsapp"></i>
                        </a>
                      ) : (
                        <></>
                      )}

                      {/* <a href="#" className="linkedin"><i className="bx bxl-linkedin"></i></a> <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a>*/}
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-6 footer-links">
                  <h4>Enlaces Útiles</h4>
                  <ul>
                    <li>
                      <i className="bx bx-chevron-right"></i>
                      <Link to={`${usuario?.usuario?.local}`}>Inicio</Link>
                    </li>
                    {usuario?.usuario?.local === "ClickMenu" ? (
                      <li>
                        <i className="bx bx-chevron-right"></i>
                        <Link
                          to={`/Locales`}
                          onClick={() => handleClick("menu")}
                        >
                          Locales
                        </Link>
                      </li>
                    ) : (
                      <li>
                        <i className="bx bx-chevron-right"></i>
                        <Link
                          to={`${usuario?.usuario?.local}/menu`}
                          onClick={() => handleClick("menu")}
                        >
                          Menú
                        </Link>
                      </li>
                    )}

                    {/* <li><i className="bx bx-chevron-right"></i> <Link  to={`${usuario?.usuario?.local}/especial`}>Especial</Link></li> */}
                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <Link to={`${usuario?.usuario?.local}/contacto`}>
                        Contacto
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-4 col-md-6 footer-newsletter">
                  <h4>Ofertas y Novedades</h4>
                  <p>Recibí actualizaciones de s especiales</p>
                  <form>
                    <input type="email" name="email" />
                    <input
                      className="subcribite center"
                      type=""
                      value="Subscribete"
                      data-bs-toggle="modal"
                      data-bs-target="#DatosModal"
                    />
                  </form>

                  <div
                    className="modal fade"
                    id="DatosModal"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content modal-contentMenu">
                        <div className="modal-header">
                          <span
                            data-bs-dismiss="modal"
                            className="btn-close material-icons-outlined"
                            translate="no"
                          >
                            close
                          </span>
                        </div>
                        <div className="modal-body modal-bodyMenu">
                          <form onSubmit={(event) => addCliente(event)}>
                            <div className="row">
                              <h4 className="center">CONFIMAR TUS DATOS</h4>
                              <div className=" form-group">
                                <input
                                  type="text"
                                  name="name"
                                  className="form-control"
                                  id="name"
                                  placeholder="Tu Nombre"
                                  value={nombre}
                                  onChange={(e) => setNombre(e.target.value)}
                                  required
                                />
                              </div>
                            </div>
                            <div className=" form-group">
                              <input
                                type="number"
                                className="form-control"
                                name="telefono"
                                id="celular"
                                placeholder="Tu Celular"
                                value={telefono}
                                pattern="[0-9]{1,10}"
                                onChange={(e) => setTelefono(e.target.value)}
                                required
                              />
                            </div>
                            <div className="form-group mt-3">
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                id="email"
                                placeholder="Tu Email (opcional)"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>
                            <div>
                              <input
                                type="checkbox"
                                name="subscrito"
                                value="True"
                                checked={true}
                              />
                              <h6>Subscribirme para recibir promociones</h6>
                            </div>

                            {/* <div >
                <div className="loading d-block"></div>
                <div className="error-message"></div>
              </div> */}

                            <div className="center">
                              {ok === 200 ? (
                                <button className="normal end second">
                                  <div className="loading d-block"></div>
                                </button>
                              ) : (
                                <button
                                  className="normal subscri end"
                                  type="submit"
                                >
                                  <h6>Sucribirme</h6>
                                </button>
                              )}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container ">
            <Link to="">
              {" "}
              <div className="footerlogo">
                <img alt="ClickMenu tu mejor opción" src="https://dewey.tailorbrands.com/production/brand_version_mockup_image/545/8388125545_4e7e1ce8-799d-4804-a9d9-425c2919e42a.png?cb=1685663380" />
              </div>
            </Link>{" "}
            <div className="copyright">
              &copy; Copyright{" "}
              <strong>
                <span>SkySolutions</span>
              </strong>
              . Derechos Reservados
            </div>
            <div className="credits">
              Desarrollo <a id="footerLink">Jeampierre Gonzalez</a>
            </div>
          </div>
        </footer>
      ) : (
        fetchUsuario
      )}
      <a className="back-to-top d-flex align-items-center justify-content-center">
        <i className="bi bi-arrow-up-short"></i>
      </a>
    </div>

    // <div id="preloader"></div>
  );
}
