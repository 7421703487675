import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AppContext from "../context/AppContext";
import { useCookies } from 'react-cookie';

export default function HomeClickMenu() {

  const [usuario, setUsuario] = useCookies(['usuario']);
  const { api } = useContext(AppContext);

  const [cookies, setCookie] = useCookies(['formData']);
  let nombre = null;
  if (cookies.formData) {
    nombre = cookies.formData.nombre;

  }




  useEffect(() => {

    window.scrollTo(0, 0);
        const fetchUsuario = async () => {
      try {
        const response = await fetch(`${api}/api/usuarios/name/ClickMenu`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ name: "ClickMenu" }) // Datos a enviar en el cuerpo de la solicitud
        });
        const data = await response.json();
        setUsuario('usuario', data, { path: '/', maxAge: 604800 });


      } catch (error) {
        console.log('Error al obtener el usuario:', error);
      }
    };
    fetchUsuario();
    
  
  }, []);


  const getDynamicStyles = () => {
    // Lógica para determinar los estilos según el identificador
    if (usuario.usuario) {

      return {
        // background: `url() bottom center `,
        color: 'white',
        overflow: "clip",
      };

    }
  };

  const dynamicStyles = getDynamicStyles();

  return (
         usuario.usuario  ? 
    <div style={dynamicStyles}>
      <section id="hero" className="menuCM d-flex align-items-center" >
        <div className="container position-relative text-center text-lg-start" data-aos="zoom-in" data-aos-delay="100">
          <div className="row">
            <div className="col-lg-8"><h2>{nombre ? nombre : ''}</h2>

              <h1>Bienvenido a <span >ClickMenu</span></h1>   <br />
              <h2>ELEGÍ, PEDÍ y PAGÁ desde tu celular.</h2>
              <h2>¡Mejor servicio, más tiempo para disfrutar!</h2>
              {/* <h2>Todo con solo un par de clicks</h2> */}

              <div className="btns">
                <Link to={`/Locales`} className="btn-menu animated fadeInUp scrollto">Locales</Link>
                <Link to={`/ClickMenu/Sumarme`} className="btn-book animated fadeInUp scrollto">Sumarte</Link>
              </div>
            </div>
            <div className="col-lg-4 d-flex align-items-center justify-content-center position-relative" data-aos="zoom-in" data-aos-delay="200">
              <Link to={`/${usuario.usuario.local}/Sumarme`} className="glightbox play-btn"></Link>
            </div>

          </div>
        </div>
      </section>

      <section id="why-us" className="sectionMenu menu why-us">
        <div className="container" data-aos="fade-up">

          <div className="section-title">
            <h2>Beneficios </h2>
            <p>Por qué ClickMenu</p>
          </div>

          <div className="row">

            <div className="col-lg-4">
              <div className="box" data-aos="zoom-in" data-aos-delay="100">
                <span>01</span>
                <h4>+ Ecológico<br></br>- Costos</h4>
                <p>Ahorro de impresiones de menus y facturas. <br></br>El costo más bajo del mercado.</p>
              </div>
            </div>

            <div className="col-lg-4 mt-4 mt-lg-0">
              <div className="box" data-aos="zoom-in" data-aos-delay="200">
                <span>02</span>
                <h4>Comodidad y elegancia</h4>
                <p>Evita espera de la carta, para pedir o pagar. <br></br>Diseño elegante y vanguardista. </p>
              </div>
            </div>

            <div className="col-lg-4 mt-4 mt-lg-0">
              <div className="box" data-aos="zoom-in" data-aos-delay="300">
                <span>03</span>
                <h4>Diferenciación de Marca</h4>
                <p>Genera una experiencia moderna, trasmitiendo calidad y diferenciación.</p>
              </div>
            </div>

          </div>

        </div>
      </section>
      <section id="about" className="about sectionMenu menu">
        <div className="container" data-aos="fade-up">

          <div className="row">
            {/* <div className="col-lg-6 order-1 order-lg-2" data-aos="zoom-in" data-aos-delay="100">
            <div className="about-img">
              <img src="https://plus.unsplash.com/premium_photo-1661757524972-a54918ca118d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80" alt=""/>
            </div>
          </div> */}
            <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content center">
              <h3 className="flex">CLICKMENU ES UN SOFTWARE WEB SIMPLE, ÁGIL Y SEGURO QUE REALZA TU SERVICIO GASTRONÓMICO</h3>
              {/* <p className="fst-italic">
             
            </p> */}
              <ul>
                <li><i className="bi bi-check-circle"></i> Aplicación web autoadministrada por el restaurante, permitiendo modificar el menú en forma instantánea.</li>
                <li><i className="bi bi-check-circle"></i> QR del menú digital, pedido y pago digital. No es necesario descargar una app.</li>
                <li><i className="bi bi-check-circle"></i> Evita largas filas y tiempos de espera.</li>
              </ul>
              <p>

              </p>
            </div>
          </div>

        </div>
      </section>
      {/* 
    <section id="testimonials" className=" sectionMenu menu testimonials section-bg">
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>Testimonios</h2>
          <p>What they're saying about us</p>
        </div>

        <div className="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
          <div className="swiper-wrapper">

            <div className="swiper-slide">
              <div className="testimonial-item">
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam, risus at semper.
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
                <img src="assets/img/testimonials/testimonials-1.jpg" className="testimonial-img" alt=""/>
                <h3>Saul Goodman</h3>
                <h4>Ceo &amp; Founder</h4>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="testimonial-item">
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  Export tempor illum tamen malis malis eram quae irure esse labore quem cillum quid cillum eram malis quorum velit fore eram velit sunt aliqua noster fugiat irure amet legam anim culpa.
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
                <img src="assets/img/testimonials/testimonials-2.jpg" className="testimonial-img" alt=""/>
                <h3>Sara Wilsson</h3>
                <h4>Designer</h4>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="testimonial-item">
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  Enim nisi quem export duis labore cillum quae magna enim sint quorum nulla quem veniam duis minim tempor labore quem eram duis noster aute amet eram fore quis sint minim.
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
                <img src="assets/img/testimonials/testimonials-3.jpg" className="testimonial-img" alt=""/>
                <h3>Jena Karlis</h3>
                <h4>Store Owner</h4>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="testimonial-item">
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  Fugiat enim eram quae cillum dolore dolor amet nulla culpa multos export minim fugiat minim velit minim dolor enim duis veniam ipsum anim magna sunt elit fore quem dolore labore illum veniam.
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
                <img src="assets/img/testimonials/testimonials-4.jpg" className="testimonial-img" alt=""/>
                <h3>Matt Brandon</h3>
                <h4>Freelancer</h4>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="testimonial-item">
                <p>
                  <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                  Quis quorum aliqua sint quem legam fore sunt eram irure aliqua veniam tempor noster veniam enim culpa labore duis sunt culpa nulla illum cillum fugiat legam esse veniam culpa fore nisi cillum quid.
                  <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
                <img src="assets/img/testimonials/testimonials-5.jpg" className="testimonial-img" alt=""/>
                <h3>John Larson</h3>
                <h4>Entrepreneur</h4>
              </div>
            </div>

          </div>
          <div className="swiper-pagination"></div>
        </div>

      </div>
    </section> */}

      <section id="stats-counter" className="stats-counter">
        <div className="container" data-aos="zoom-out">

          <div className="row gy-4">

            <div className="col-lg-3 col-md-6">
              <div className="stats-item text-center w-100 h-100">
                <span data-purecounter-start="0" data-purecounter-end="232" data-purecounter-duration="1" className="purecounter">+30'</span>
                <p>Minutos de ahorro</p>
              </div>
            </div>



            {/* <div className="col-lg-3 col-md-6">
            <div className="stats-item text-center w-100 h-100">
              <span data-purecounter-start="0" data-purecounter-end="1453" data-purecounter-duration="1" className="purecounter">+1200</span>
              <p>Pedidos exitosos</p>
            </div>
          </div> */}

            <div className="col-lg-3 col-md-6">
              <div className="stats-item text-center w-100 h-100">
                <span data-purecounter-start="0" data-purecounter-end="32" data-purecounter-duration="1" className="purecounter">-90%</span>
                <p>Menos de comisiones</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="stats-item text-center w-100 h-100">
                <span data-purecounter-start="0" data-purecounter-end="521" data-purecounter-duration="1" className="purecounter">24/7</span>
                <p>Soporte técnico</p>
              </div>
            </div>

          </div>

        </div>
      </section> </div>
: <div id="preloader"></div>  


    )

}